<template>
  <div class="cart">
    <div :class="['ec-cart-widget', {'scrolled': !navigation.scrolled}]"></div>
    <div>
      <script2 data-cfasync="false" type="text/javascript"
               src="https://app.ecwid.com/script.js?28500096&data_platform=code&data_date=2020-04-30" charset="utf-8"/>
      <script2 type="text/javascript">Ecwid.init();</script2>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VS2 from 'vue-script2';

  Vue.use(VS2);

  export default {
    name: 'shopCart',
    data() {
      return {
        navigation: {
          scrolled: false,
        },
      };
    },
    mounted() {
      window.addEventListener('scroll', () => {
        this.navigation.scrolled = window.scrollY > 0 || window.pageYOffset > 0;
      });
    },
    methods: {
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/layout";
  .cart {
    .ec-cart-widget {
      margin-top: 7px;
    }
    .scrolled {
      &.ec-cart-widget {
        .ec-minicart {
          .ec-minicart__body {
            .ec-minicart__icon {
              svg {
                g {
                  path {
                    @include MQ(XL) {
                      stroke: white !important;
                    }
                    @include MQ(L) {
                      stroke: white !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
